
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
@Component({
    components: {
        Draggable
    }
})
export default class List extends Vue {
    @Prop({ default: () => [] }) value!: any[]

    get list() {
        return this.value
    }

    set list(val) {
        this.$emit('input', val)
    }
    handleDelete(index: number) {
        this.list.splice(index, 1)
    }
}
