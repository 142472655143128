




























import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import Detail from './detail.vue'
@Component({
    components: {
        LsDialog,
        Detail
    }
})
export default class Dialog extends Vue {
    @Prop({ default: () => [] }) value!: any[] | object
    @Prop({ default: 'multiple' }) type!: 'multiple' | 'single'
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: 50 }) limit!: number
    @Prop({ default: false }) isSpec!: boolean
    visible = false
    goods = []
    @Watch('value', { immediate: true })
    valueChange(val: any) {
        this.goods = JSON.parse(JSON.stringify(val))
    }

    handleConfirm() {
        this.$emit('input', this.goods)
    }
}
